import { Availability, Slot } from "../types";
import moment from "moment";  // Import moment

// Helper to get day of the week from date string
const getDayOfWeek = (dateString: string): string => {
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const date = moment(dateString, "YYYY-MM-DD");
    return days[date.day()]; // moment().day() gives day of the week (0 for Sunday, 6 for Saturday)
};

// Helper to find intersection of time slots
const findIntersection = (availabilitySlots: Slot[], jsonSlots: string[]): Slot[] => {
    const intersection: Slot[] = [];

    // Check if the time from JSON matches the time in Availability
    availabilitySlots.forEach((availSlot) => {
        const startHour = availSlot.start_time;
        const endHour = availSlot.end_time;

        // Create a range of hours based on availability using moment
        const availableHours = generateHourlyRange(startHour, endHour);

        // Find common hours between availability and free slots in JSON
        availableHours.forEach((hour) => {
            if (jsonSlots.includes(hour)) {
                // If an hour from availability is present in the JSON slots, add it
                intersection.push({ start_time: hour, end_time: moment(hour, "H:mm").add(1, 'hours').format("H:mm") });
            }
        });
    });

    return intersection;
};

// Helper to generate an array of hourly time slots based on start and end time (e.g., "9:00" to "17:00")
const generateHourlyRange = (startTime: string, endTime: string): string[] => {
    const start = moment(startTime, "H:mm");
    const end = moment(endTime, "H:mm");

    const range: string[] = [];
    const current = start.clone();

    // Loop to generate each hour in the range
    while (current.isBefore(end)) {
        range.push(current.format("H:mm"));
        current.add(1, 'hours');
    }

    return range;
};

export const calculateIntersectionForDate = (
    availability: Availability,
    jsonSlots: { [date: string]: string[] },
    date: string // Accept a specific date as a string
): Slot[] => {

    // Get the day of the week for the provided date
    const dayOfWeek = getDayOfWeek(date); // e.g., "monday", "tuesday", etc.

    // Free slots for the provided date
    const freeSlotsForDate = jsonSlots[date];

    // If no free slots are available in the JSON for that date, return an empty array
    if (!freeSlotsForDate) return [];

    // Check if availability exists for the corresponding day of the week
    if (availability[dayOfWeek as keyof Availability]) {
        const availableSlots = availability[dayOfWeek as keyof Availability]!;

        // Find the intersection of available slots and free slots from the JSON
        const intersectedSlots = findIntersection(availableSlots, freeSlotsForDate);

        return intersectedSlots; // Return the intersected slots for the specific date
    }

    return []; // Return an empty array if no availability exists for that day of the week
};

/**
 *
 * @param date
 * @returns
 */
export const formatTimeDifference = (date: string) => {
    const now = moment();
    const givenDate = moment(date);
    const duration = moment.duration(givenDate.diff(now));

    if (duration.asYears() >= 1) {
        const years = Math.floor(duration.asYears());
        const months = Math.floor(
            duration.subtract(moment.duration(years, "years")).asMonths()
        );
        return `${years} year${years > 1 ? "s" : ""} and ${months} month${months > 1 ? "s" : ""}`;
    } else if (duration.asMonths() >= 1) {
        const months = Math.floor(duration.asMonths());
        const days = Math.floor(
            duration.subtract(moment.duration(months, "months")).asDays()
        );
        return `${months} month${months > 1 ? "s" : ""} and ${days} day${days > 1 ? "s" : ""}`;
    } else if (duration.asWeeks() >= 1) {
        const weeks = Math.floor(duration.asWeeks());
        const days = Math.floor(duration.subtract(moment.duration(weeks, "weeks")).asDays());
        return `${weeks} week${weeks > 1 ? "s" : ""} and ${days} day${days > 1 ? "s" : ""}`;
    } else if (duration.asDays() >= 1) {
        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.subtract(moment.duration(days, "days")).asHours());
        return `${days} day${days > 1 ? "s" : ""} and ${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.subtract(moment.duration(hours, "hours")).asMinutes());
        return `${hours} hour${hours > 1 ? "s" : ""} and ${minutes} minute${minutes > 1 ? "s" : ""}`;
    }
};


export const formatTimeAgo = (date: string) => {
    const now = moment();
    const givenDate = moment(date);
    const duration = moment.duration(now.diff(givenDate));

    if (duration.asYears() >= 1) {
        const years = Math.floor(duration.asYears());
        const months = Math.floor(
            duration.subtract(moment.duration(years, "years")).asMonths()
        );
        return `${years} year${years > 1 ? "s" : ""}${months > 0 ? ` ${months} month${months > 1 ? "s" : ""}` : ""} ago`;
    } else if (duration.asMonths() >= 1) {
        const months = Math.floor(duration.asMonths());
        const days = Math.floor(
            duration.subtract(moment.duration(months, "months")).asDays()
        );
        return `${months} month${months > 1 ? "s" : ""}${days > 0 ? ` ${days} day${days > 1 ? "s" : ""}` : ""} ago`;
    } else if (duration.asWeeks() >= 1) {
        const weeks = Math.floor(duration.asWeeks());
        const days = Math.floor(duration.subtract(moment.duration(weeks, "weeks")).asDays());
        return `${weeks} week${weeks > 1 ? "s" : ""}${days > 0 ? ` ${days} day${days > 1 ? "s" : ""}` : ""} ago`;
    } else if (duration.asDays() >= 1) {
        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.subtract(moment.duration(days, "days")).asHours());
        return `${days} day${days > 1 ? "s" : ""}${hours > 0 ? ` ${hours} hour${hours > 1 ? "s" : ""}` : ""} ago`;
    } else {
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.subtract(moment.duration(hours, "hours")).asMinutes());
        return `${hours} hour${hours > 1 ? "s" : ""}${minutes > 0 ? ` ${minutes} minute${minutes > 1 ? "s" : ""}` : ""} ago`;
    }
};


export const getDaysWithSlots = (availability: Availability): number[] => {
    // Map days of the week to numbers (Monday = 1, Sunday = 7)
    const daysMapping: { [key: string]: number } = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
    };

    // Iterate through each day and collect day numbers with slots
    const daysWithSlots: number[] = Object.entries(availability)
        .filter(([_, slots]) => slots && slots.length > 0) // Filter days with slots
        .map(([day]) => daysMapping[day.toLowerCase()]); // Map day names to numbers

    return daysWithSlots;
};
