import { useEffect, useState } from "react";
import { AppState, ClientDetails, NoteDetails } from "../../../types";
import { doc, getDoc, collection, query, orderBy, getDocs, updateDoc, onSnapshot } from "firebase/firestore";
import { getFirebaseFirestore } from "../../../const";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Box, Breadcrumbs, Button, Chip, CircularProgress, DialogActions, Link, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CancelIcon from '@mui/icons-material/Cancel';
import MailIcon from '@mui/icons-material/Mail';
import clientHistoryStyle from "./style/client-history.module.css"
import moment from "moment";
import { ProtectedRoute } from "../../../components/protected-route";
import { Modal, TextInput } from 'flowbite-react';
import { Edit } from "../../../components/edit";
import RecurringSessionDialog from "./recurring-dialog";
import { getFunctions, httpsCallable } from "firebase/functions";
import EndRecurring from "./end-recurring";

export const ClientHistory = ({ }) => {

    const db = getFirebaseFirestore()
    const navigate = useNavigate()
    const params = useParams()
    const clientId = params["clientid"];
    const user = useSelector((state: AppState) => state.sereneUser)
    const [email, setEmail] = useState("");
    const [editEmail, setEditEmail] = useState(false);
    const [clientDetails, setClientDetails] = useState<ClientDetails | null>(null);
    const [notes, setNotes] = useState<NoteDetails[]>([]);
    const [loading, setLoading] = useState(true);
    const [showRecurringDialog, setShowRecurringDialog] = useState(false)
    const [showEndRecurring, setShowEndRecurring] = useState(false)
    const [endRecurrenceLoaing, setEndRecurrenceLoading] = useState(false)
    const [recurrenceLoaing, setRecurrenceLoading] = useState(false)



    useEffect(() => {
        if (user == null || !user.fetched) {
            return;
        }

        var snapshot: any = null;


        const fetchClientDetailsAndNotes = async () => {
            setLoading(true);
            try {
                // Fetch client details
                const clientDocRef = doc(db, `/serene_users/${user.id}/client_records/${clientId}`);
                snapshot = onSnapshot(clientDocRef, (clientSnapshot) => {
                    if (clientSnapshot.exists()) {
                        setClientDetails({
                            id: clientId,
                            ...clientSnapshot.data()
                        } as ClientDetails);
                    }
                })

                // Fetch notes associated with the client, sorted by timestamp
                const notesCollectionRef = collection(db, `/serene_users/${user.id}/client_records/${clientId}/client_notes`);
                const notesQuery = query(notesCollectionRef, orderBy('timestamp', 'desc'));
                const notesSnapshot = await getDocs(notesQuery);

                const notesList: NoteDetails[] = notesSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as NoteDetails[];

                setNotes(notesList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching client details or notes: ", error);
            }
        };

        fetchClientDetailsAndNotes();

        return () => {
            if (snapshot) {
                snapshot()
            }

        }

    }, [user, clientId]);

    const bdi = notes.map((n, i) => {
        return {
            time: n.timestamp,
            value: Object.values(n?.bdi_score ?? { total: 0 }).reduce((p, c) => p + c, 0)
        }
    })

    const formatUntilDate = (date: Date) => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getUTCDate()).padStart(2, "0");
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const seconds = String(date.getUTCSeconds()).padStart(2, "0");

        // Combine into the UNTIL format
        return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
    }

    /**
     * 
     * @param sessionData 
     * @returns 
     */
    const createRecurringSession = async (sessionData: {
        startDate: Date;
        startTime: string;
        endTime: string;
        frequency: string;
        endDate: Date;
    }) => {
        setRecurrenceLoading(true)
        // Extract and prepare data
        const { startDate, startTime, endTime, frequency, endDate } = sessionData;

        // Concatenate date with times
        const startDateTime = new Date(`${startDate.toISOString().split("T")[0]}T${startTime}`);
        const endDateTime = new Date(`${startDate.toISOString().split("T")[0]}T${endTime}`);

        // Get ISO Strings
        const startDateTimeISO = startDateTime.toISOString();
        const endDateTimeISO = endDateTime.toISOString();
        // Map frequency to iCalendar recurrence rule (RRULE)
        const recurrenceMapping: { [key: string]: string } = {
            "once-a-week": "FREQ=WEEKLY;INTERVAL=1",
            "every-two-weeks": "FREQ=WEEKLY;INTERVAL=2",
            "once-a-month": "FREQ=MONTHLY;INTERVAL=1",
        };
        const recurrenceRule = recurrenceMapping[frequency];

        // Calculate the recurrence end rule
        const recurrenceEnd = formatUntilDate(endDate);
        const fullRecurrence = `${recurrenceRule};UNTIL=${recurrenceEnd}`;

        // Prepare the data payload
        const payload = {
            start: startDateTimeISO,
            end: endDateTimeISO,
            recurrence: fullRecurrence,
            clientId: clientId
        };

        try {
            // Call the Firebase function
            const createSession = httpsCallable(getFunctions(), "recurringSessions");
            const response = await createSession(payload);

            console.log("Recurring session created successfully:", response.data);
            setShowRecurringDialog(false)
            setRecurrenceLoading(false)
        } catch (error) {
            console.error("Error creating recurring session:", error);
            setRecurrenceLoading(false)
        }
    };


    const getType = () => {
        if (loading) {
            return ""
        }

        const newClientCutOff = moment().subtract(1, "month").unix() * 1000
        const requiresFollowUpCutoff = moment().subtract(2, "week").unix() * 1000

        if ((clientDetails?.creation_timestamp ?? 0) > newClientCutOff) {
            return "New"
        }

        if ((clientDetails?.last_booking_timestamp ?? 0) > requiresFollowUpCutoff) {
            return "Requires Follow Up"
        }
        return ""

    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/dashboard" >
            Dashboard
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            href="/dashboard#notes"
        >
            Notes
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            History
        </Typography>,
    ];

    const clientType = getType()

    return (
        <ProtectedRoute>
            {!loading ? <div className={clientHistoryStyle.container}>
                <EndRecurring show={showEndRecurring}
                    onClose={() => { setShowEndRecurring(false) }}
                    onSubmit={async () => {
                        try {
                            setEndRecurrenceLoading(true)
                            // Call the Firebase function
                            const removeRecurringSessions = httpsCallable(getFunctions(), "removeRecurringEvent");
                            const response = await removeRecurringSessions({
                                clientId: clientDetails?.id ?? ""
                            });

                            console.log("Recurring session deleted successfully:", response.data);
                            setEndRecurrenceLoading(false)
                        } catch (error) {
                            console.error("Error removing recurring session:", error);
                            setEndRecurrenceLoading(false)
                        }

                        setShowEndRecurring(false)
                    }}
                    endLoading={endRecurrenceLoaing} />
                <RecurringSessionDialog show={showRecurringDialog}
                    onClose={() => setShowRecurringDialog(false)}
                    onSubmit={createRecurringSession}
                    recurrenceLoading={recurrenceLoaing} />
                <Modal show={editEmail} onClose={() => { setEditEmail(false) }}>
                    <Modal.Header >
                        <Typography variant='h5' fontWeight={"bolder"}>Edit Email</Typography>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6">
                            <Typography variant='body1' color={"text.secondary"}>Email is used for any communication between you and your client.</Typography>
                        </div>

                        <br />
                        <TextInput aria-label="email" type="email" placeholder="JohnDoe@gmail.com" style={{ height: 50 }}
                            onChange={(e) => {
                                const value = e.target.value;
                                setEmail(value);
                            }} />


                        {/* <div className={dahsboardStyle.center_progress}>
                        <CircularProgress value={uploadProgress} size={50} thickness={4} variant='determinate' />
                    </div> */}
                    </Modal.Body>

                    <DialogActions>
                        <Button sx={{ borderRadius: "5px", marginRight: "20px" }}
                            onClick={() => {
                                setEditEmail(false)
                            }}>
                            Cancel
                        </Button>
                        <Button sx={{ borderRadius: "5px", marginRight: "20px" }}
                            onClick={async () => {
                                const clientDocRef = doc(db, `/serene_users/${user.id}/client_records/${clientId}`);
                                await updateDoc(clientDocRef, {
                                    email: email
                                });
                                setEditEmail(false)
                            }}>
                            Save
                        </Button>
                    </DialogActions>
                    <br />

                </Modal>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                    <div className={clientHistoryStyle.header_actions}>
                        {/* <div onClick={() => navigate(-1)}>
                                    <MailIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px", color: "#D33E43" }} />
                                </div> */}
                        <div onClick={() => navigate(-1)}>
                            <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                        </div>
                    </div>
                </Box>
                <br />
                {clientDetails && (
                    <div className={clientHistoryStyle.client_details}>
                        <div className={clientHistoryStyle.header}>
                            <div className={clientHistoryStyle.name_container}>
                                <Typography variant="h5" fontWeight={"bolder"}>{clientDetails.client_name}</Typography>
                                {clientType != "" && <Chip
                                    label={clientType}
                                    sx={{
                                        padding: "20px",
                                        cursor: "pointer",
                                        borderRadius: "30px",
                                        backgroundColor: "black",
                                        opacity: 0.5,
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "black",
                                        },
                                    }}
                                />}

                                {clientDetails.recurring && <Chip
                                    label={"Recurring Sessions"}
                                    sx={{
                                        padding: "20px",
                                        cursor: "pointer",
                                        borderRadius: "30px",
                                        backgroundColor: "green",
                                        opacity: 0.5,
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "green",
                                        },
                                    }}
                                />}
                            </div>

                        </div>
                        <br />
                        {!user.calenderConnected && <div>
                            <Alert severity="error"
                                sx={{ borderRadius: "20px", paddingBottom: "15px", paddingTop: "15px" }}
                                action={
                                    <Chip
                                        label={" Setup"}
                                        variant="outlined"
                                        size="medium"
                                        sx={{
                                            paddingRight: "10px", paddingLeft: "10px", marginRight: "10px"
                                        }}
                                        onClick={() => navigate("/setup-scheduling")}
                                    />


                                }>
                                You need to connect your calender before you can setup a recurring session
                            </Alert>

                            <br />
                        </div>}

                        <div className={clientHistoryStyle.details_section}>
                            <div>
                                <div className={clientHistoryStyle.email_edit}>
                                    <Typography variant="h6" fontWeight={"bolder"}><span className={clientHistoryStyle.email}>{!clientDetails.email ? "Email Not assigned" : clientDetails.email}</span></Typography>
                                    <Edit onClick={() => { setEditEmail(true) }} />
                                </div>
                                {/* <Typography variant="body1" color={"grey"}>Upcoming session on </Typography> */}
                                {/* <Typography variant="body1" color={"grey"}>Last updated on {moment(clientDetails.last_update || 0).format("MMM Do hh:mm A")}</Typography> */}
                                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                    {/* <Typography variant="body1" color={"grey"}>Recurring sessions are turned off</Typography> */}

                                </div>
                            </div>
                            <div>
                                {/* TODO -> THIS IS ONLY POSSIBLE IF CALENDER IS CONNECTED */}
                                {!clientDetails.recurring && <Button variant="outlined"
                                    onClick={() => setShowRecurringDialog(true)}
                                    disableElevation
                                    disabled={!user.calenderConnected || !clientDetails.email}
                                    sx={{
                                        borderRadius: "20px"
                                    }}>Setup Recurring Sessions</Button>}
                                {clientDetails.recurring && <Button variant="outlined"
                                    onClick={() => { setShowEndRecurring(true) }}
                                    disableElevation
                                    sx={{
                                        borderRadius: "20px"
                                    }}>End Recurring Sessions</Button>}
                            </div>

                        </div>
                    </div>
                )}

                {!clientDetails?.email && <><br /><Alert severity="error">Please assign an email to this client. The email is essential for scheduling, communication, and enabling other key features of the platform.</Alert></>}


                {/* <div className={clientHistoryStyle.client_notes}> */}
                {/* <Typography variant="h4" fontWeight={"bolder"}>📈 Progress</Typography>
                    <br />
                    <div className={clientHistoryStyle.progress_secition}>
                        <div className={clientHistoryStyle.chart_container}>
                            <Typography variant="body2">Beck Depression Inventory Scores</Typography>
                            <br />
                            <BdiProgress data={bdi} />
                        </div>
                        <div className={clientHistoryStyle.spacer} />
                        <div className={clientHistoryStyle.chart_container_right}>
                            <Typography variant="body2">Beck Depression Inventory Scores</Typography>
                            <br />
                            <BdiProgress data={bdi} />
                        </div>
                    </div> */}
                {/* </div> */}

                {/* Bottom part: List of Notes */}
                <div className={clientHistoryStyle.client_notes}>
                    <Typography variant="h4" fontWeight={"bolder"}>📑 Records ({notes.length})</Typography>
                    <br />
                    {notes.length > 0 ? (
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {notes.map((note) => (
                                <li key={note.id} className={clientHistoryStyle.list_item} onClick={() => { navigate(`/note/${note?.id}`) }}>
                                    <div className={clientHistoryStyle.list_body}>
                                        <div>
                                            <p>🕓 {moment(note.timestamp).format("MMM Do hh:mm A")}</p>
                                            <div className={note.pending ? clientHistoryStyle.pending : clientHistoryStyle.approved}>
                                                {note.pending ? "Pending" : "Approved"}
                                            </div>
                                        </div>
                                        <NavigateNextIcon sx={{ fontSize: "40px" }} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No notes are available</p>
                    )}
                </div>
                <br />
                <br />
            </div> : <div className={clientHistoryStyle.container_loading}><CircularProgress /></div>}
        </ProtectedRoute>
    );
}