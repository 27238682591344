import { useState } from 'react';
import { Sidebar } from 'flowbite-react';
import { HiOutlineMenu, HiOutlineX, HiHome, HiClipboardList, HiCalendar, HiUsers, HiQuestionMarkCircle, HiLogout } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import logo from "../../../assets/logo.svg";
import { getFirebaseAuth } from '../../../const';
import { signOut } from 'firebase/auth';

const DashboardSidebar = ({ user, selectedIndex, setSelectedIndex }: { user: any, selectedIndex: number, setSelectedIndex: any }) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const isMobile = useMediaQuery('(max-width: 700px)');
    const navigate = useNavigate();
    const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

    const menuItems = [
        { id: 0, label: 'Home', icon: HiHome, route: '#home', visible: true },
        { id: 1, label: 'Notes', icon: HiClipboardList, route: '#notes', visible: user.profileCompleted },
        { id: 2, label: 'Calendar', icon: HiCalendar, route: '#calendar', visible: user.calenderConnected },
        { id: 3, label: 'Clients', icon: HiUsers, route: '#clients', visible: user.profileCompleted },
    ];

    return (
        <div style={{
            maxWidth: isMobile ? sidebarVisible ? "1000px" : "0px" : "1000px",
            overflow: "hidden"
        }}>
            {isMobile && (
                <button
                    onClick={toggleSidebar}
                    className="absolute right-4 top-4 z-50 bg-white p-2 rounded-md shadow-md"
                >
                    {sidebarVisible ? <HiOutlineX size={24} /> : <HiOutlineMenu size={24} />}
                </button>
            )}

            <Sidebar
                collapsed={isMobile}
                aria-label="Dashboard Sidebar"
                theme={{
                    root: {
                        inner: "h-full overflow-y-auto overflow-x-hidden rounded bg-gray-50 px-3 py-4 dark:bg-gray-800 flex flex-col"
                    }
                }}
                className={`h-screen transition-transform duration-300 ${isMobile
                    ? sidebarVisible
                        ? 'translate-x-0'
                        : '-translate-x-full'
                    : 'translate-x-0'
                    }`}
            >
                <br />
                <Sidebar.Logo href="#" img={logo} imgAlt="" theme={{ img: "rounded-full h-10 w-10 mr-2" }}>
                    Vybz Health
                </Sidebar.Logo>
                <br />
                <Sidebar.Items style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <Sidebar.ItemGroup>
                        {menuItems.map(
                            (item) =>
                                item.visible && (
                                    <Sidebar.Item
                                        key={item.id}
                                        icon={item.icon}
                                        theme={{
                                            icon: {
                                                base: selectedIndex === item.id ? "text-white" : "text-black",
                                            }
                                        }}
                                        className={
                                            selectedIndex === item.id
                                                ? 'bg-black text-white hover:bg-black cursor-pointer'
                                                : 'hover:bg-gray-100 cursor-pointer'
                                        }
                                        onClick={() => {
                                            setSelectedIndex(item.id);
                                            navigate(item.route);
                                        }}
                                    >
                                        {item.label}
                                    </Sidebar.Item>
                                )
                        )}
                    </Sidebar.ItemGroup>
                    <div style={{ flex: 1 }}></div>
                    {/* Add Help Center at the bottom */}
                    <Sidebar.ItemGroup>
                        <Sidebar.Item
                            icon={HiQuestionMarkCircle}
                            className="hover:bg-gray-100"
                            theme={{
                                icon: {
                                    base: "text-black"
                                }
                            }}
                            onClick={() => {
                                window.open('https://vybz-health.gitbook.io/serene-docs/', "_blank");
                            }}
                        >
                            Help Center
                        </Sidebar.Item>
                        <Sidebar.Item
                            icon={HiLogout}
                            className="hover:bg-gray-100"
                            theme={{
                                icon: {
                                    base: "text-black"
                                }
                            }}
                            onClick={() => {
                                signOut(getFirebaseAuth()).then(() => {
                                    navigate("/login");
                                })
                            }}
                        >
                            Logout
                        </Sidebar.Item>
                    </Sidebar.ItemGroup>

                </Sidebar.Items>


            </Sidebar>
        </div>
    );
};

export default DashboardSidebar;
