import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { Growth } from "../types";

const KPICard = ({ number, title, growth }: { number: number, title: string, growth: Growth | undefined }) => (
    <Card
        elevation={0}
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            alignSelf: "stretch",
            borderRadius: "20px",
            backgroundColor: "white",
        }}
    >
        <CardContent sx={{ textAlign: "center" }}>
            <Typography
                variant="h2"
                component="div"
                sx={{ fontWeight: "bold", color: "#4CAF50" }}
            >
                {number}
            </Typography>

            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                {title}
            </Typography>
            {growth && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 2,
                    }}
                >
                    {growth.direction === "positive" && (
                        <ArrowUpward fontSize="small" sx={{ color: "green", mr: 0.5 }} />
                    )}
                    {growth.direction === "negative" && (
                        <ArrowDownward sx={{ color: "red", mr: 0.5 }} />
                    )}
                    <Typography
                        variant="h6"
                        sx={{
                            color: growth.direction === "positive" ? "green" : "red",
                            fontWeight: "bold",
                        }}
                    >
                        {growth.value}%
                    </Typography>
                </Box>
            )}
        </CardContent>
    </Card>
);

export default KPICard;
