import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { locales } from 'iso-lang-codes';

const firebaseConfig = {
    apiKey: "AIzaSyC_U8vAQvZL3EF-VnzoK3wd4UkZB76mJ8U",
    authDomain: "vybzz-336f4.firebaseapp.com",
    projectId: "vybzz-336f4",
    storageBucket: "vybzz-336f4.appspot.com",
    messagingSenderId: "849382629040",
    appId: "1:849382629040:web:323325ca5ceffd77b42575",
    measurementId: "G-PTESLEWYVR"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const getApp = () => app;
export const getFirebaseFirestore = () => getFirestore();
export const getFirebaseStorage = () => getStorage();
export const getFirebaseDatabase = () => getDatabase();
export const getFirebaseAuth = () => getAuth()
export const USER_COLLECTION = "users"
export const SERENE_NOTES = "serene_notes"
export const SERENE_VIDEOS = "serene_videos"
export const SERENE_AUDIO = "serene_audio"
// //aPwwpl4gfOUnVYd65civVfSctzJ3/client_records/jjh4cZcHD6aHAIeO499X
export const SERENE_USERS = "serene_users"
export const SERENE_CLIENT_RECORDS = "client_records"
export const SERENE_CLIENT_NOTES = "client_notes"
export const CALENDLY_CLIENT_ID = "il65xDqlnrPLRJYuLd-5AHYzNQK_IHkcZOlYRbjbKNU"
export const CALENDLY_SECRET = "XBXJxh1uOLdBZNL1hkQXpu6n-GOYRI5bp6pjRQRsP4A"
export const EXPERTISE_OPTIONS = [
    "OCD",
    "ADHD",
    "Trauma",
    "Children",
    "Relationship",
    "Anxiety",
    "Depression",
    "PTSD",
    "Substance Abuse",
    "Family Therapy",
    "Couples Therapy",
    "Cognitive Behavioral Therapy (CBT)",
    "Mindfulness",
    "Play Therapy",
    "Trauma-Informed Care",
    "Dialectical Behavior Therapy (DBT)",
    "Grief Counseling",
    "Crisis Intervention",
    "Stress Management",
    "Anger Management",
    "Eating Disorders",
    "Self-Esteem Issues",
    "Behavioral Issues",
    "Psychosis",
    "Workplace Issues",
    "Parenting Support",
    "Cultural Sensitivity",
    "Assertiveness Training",
    "Addiction Recovery",
    "Sexuality and Gender Identity",
    "LGBTQ+ Issues",
    "Motivational Interviewing",
    "Solution-Focused Therapy"
];

export const CASE_STUDIES = [
    {
        id: "augment-therapy",
        name: "Augment Therapy",
        title: "Serene drastically reduces Note Taking efforts",
        introduction: "Augment Therapy by Anurag Nair is a mental health service based in Bangalore, IN, that offers online psychological counselling for adults. The service is informed by a social justice lens and offers a client-centred approach towards better mental health.",
        details: "During an initial conversation with Anurag Nair, they shared a challenge familiar to many therapists: juggling between actively listening to clients and taking detailed notes during sessions. This dual responsibility often left them feeling overwhelmed and distracted. Anurag envisioned a tool that could seamlessly generate comprehensive therapy notes, freeing up their focus for what truly matters—connecting with their clients. Inspired by their input, they became one of the first testers of the browser co-pilot, providing critical feedback that shaped its development. Today, Anurag enthusiastically uses the co-pilot for all their online sessions, marveling at how it effortlessly transforms spoken conversations into precise, professional notes. The co-pilot has become an indispensable companion in their practice, allowing them to dedicate more energy to their clients while streamlining administrative tasks.",
        impact: "Reduced therapist admin time by 40%, improved patient engagement by 25%.",
        quote: "Serene is a wonderfully efficient platform that makes note-writing significantly convenient. Post-session note-writing can be time consuming and Serene does a good job of accurately summarising it. I particularly appreciate features like scheduling sessions and automated client notification which have made my caseload management experience a pleasure.",
        image: "https://firebasestorage.googleapis.com/v0/b/vybzz-336f4.appspot.com/o/case_studies%2Faument_therapy.png?alt=media&token=1017f610-a640-4b4c-b42b-9d4043156f94",
        socialLinks: {
            website: "https://www.augmentherapy.co/",
            instagram: "https://www.instagram.com/augmentherapy",
        }
    },
    {
        id: "mindpath-wellness",
        name: "MindPath Wellness",
        title: "Serene helps track Depression Symptoms accurately",
        introduction: "MindPath Wellness is a leading mental health service based in Melbourne, Australia, specializing in providing evidence-based therapy for individuals experiencing depression and anxiety. Their mission is to empower clients with the tools they need to achieve emotional well-being, combining cutting-edge technology with compassionate care.",
        details: "During a discussion with the MindPath Wellness team, they highlighted the challenges clinicians face in consistently tracking depression symptoms across sessions. Variations in note quality and lack of standardized symptom tracking were common issues that often led to missed insights. After integrating Serene into their workflow, the clinicians were able to utilize its AI-powered tools to identify subtle changes in clients' mental health by analyzing patterns in their session notes. The team appreciated how Serene not only standardized note-taking but also offered data-driven insights, ensuring that every detail was captured for more precise care. This innovation has transformed their practice, allowing clinicians to provide more focused and personalized support for their clients.",
        impact: "Improved depression symptom tracking accuracy by 30%, resulting in better treatment adjustments and higher client satisfaction.",
        quote: "Serene is a game-changer for our practice. Its ability to track and analyze symptoms has enhanced our therapy outcomes significantly. The platform’s accuracy and efficiency in summarizing sessions are unmatched, enabling us to deliver the best possible care to our clients.",
        image: "https://firebasestorage.googleapis.com/v0/b/vybzz-336f4.appspot.com/o/case_studies%2Fmindpath.webp?alt=media&token=590fbb24-5385-4fb2-8bc1-df4b0928bd99",
        // socialLinks: {
        //     website: "https://www.mindpathwellness.com/",
        //     instagram: "https://www.instagram.com/mindpathwellness",
        // }
    }
];

export const LANGUAGE_OPTIONS = locales();
export const LANGUAGE_KEYS = Object.values(LANGUAGE_OPTIONS)
