import { Box } from "@mui/material";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const BookingGraph = ({ selectedPeriod, loading, chartData }:
    { selectedPeriod: "Week" | "Month" | "Year", loading: boolean, chartData: any }) => {

    return (
        <Box
            sx={{
                width: { md: "50%", sm: "100%", lg: "50%" },
            }}
        >
            <div
                style={{
                    padding: "20px",
                    background: "white",
                    borderRadius: "20px",
                    position: "relative",
                    opacity: loading ? "0.6" : "1",
                    transition: "opacity 0.5s ease",
                    minHeight: "300px"
                }}
            >
                <Line
                    data={{
                        labels: loading ? chartData.labels.map(() => "") : chartData.labels,
                        datasets: [
                            {
                                label: `Number of bookings this ${selectedPeriod.toLowerCase()}`,
                                data: loading ? {} : chartData.data,
                                borderColor: "black",
                                borderWidth: 1,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                pointRadius: 0, // Remove circles at data points
                                tension: 0.4, // Make the line smooth
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 0, // Adds padding below the legend
                                bottom: 0
                            },
                        },
                        plugins: {
                            legend: {
                                display: true,
                                labels: {
                                    usePointStyle: true, // Use line/point styles instead of box
                                    pointStyle: "line", // Customize the style to only display a line
                                },
                            },
                            tooltip: { enabled: true },
                        },
                        scales: {
                            x: {
                                grid: { display: false },
                                ticks: {
                                    padding: 10, // Add padding below the label
                                    color: loading ? "white" : "grey",
                                    callback: (value: any, index: number) => {
                                        if (selectedPeriod === "Month") {
                                            // Display only every 3rd day
                                            return index % 3 === 0 ? chartData.labels[index] : "";
                                        }
                                        return chartData.labels[index];
                                    },
                                    autoSkip: false,
                                    maxRotation: 0,
                                    minRotation: 0,
                                },
                            },
                            y: {
                                grid: { display: true },
                                min: 0,
                                ticks: {
                                    precision: 0,
                                    stepSize: 5,
                                },
                                suggestedMax: 20,
                            },
                        },
                    }}
                />
            </div>
            {/* {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress />
                </Box>
            )} */}
        </Box>
    );
};

export default BookingGraph;
